<template>
  <div class="common-io h-[calc(100%-50px)]">
    <div class="grid grid-cols-2 gap-x-[20px] mb-[12px]">
      <div class="relative">
        <cds-select
          class="h-[34px]"
          :placeholder="t('object_tracing.cam-group')"
          :options="groupCamera"
          v-model:value="formState.groupCameraIds"
          :allow-clear="true"
          :label-prop="'groupNames'"
          :value-prop="'id'"
          @change="changeGroup"
          multiple
          :maxTagCount="1"
        >
        </cds-select>
        <cds-feather-icons
          v-if="formState.groupCameraIds.length === 0"
          type="chevron-down"
          class="text-ems-gray400 absolute right-2 inline-flex items-center h-full"
        />
      </div>
      <div class="relative">
        <cds-select
          class="h-[34px]"
          :placeholder="t('object_information.camera')"
          :options="listCam"
          v-model:value="formState.cameraIds"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          @change="onSearch"
          multiple
          :maxTagCount="1"
        >
        </cds-select>
        <cds-feather-icons
          v-if="formState.cameraIds.length === 0"
          type="chevron-down"
          class="text-ems-gray400 absolute right-2 inline-flex items-center h-full"
        />
      </div>
    </div>

    <cds-from-to-date
      v-model:dateFrom="formState.dateFrom"
      v-model:dateTo="formState.dateTo"
      :is-show-label="false"
      :allow-clear="true"
      class="from-to-date mb-[14px]"
      @update:dateFrom="onSearch"
      @update:dateTo="onSearch"
    />
    <div v-if="isLoading" class="spin bg-transparent h-[400px]">
      <a-spin />
    </div>
    <div v-else>
      <Empty
        v-if="
          !tracingObjectDetail.frequency.statistic ||
          tracingObjectDetail.frequency.statistic.length === 0
        "
        :description="t('common.no_data_displayed')"
        class="mt-[60px]"
      />
      <div
        v-else
        class="flex flex-col gap-[16px] cursor-pointer max-h-[calc(100vh-420px)] overflow-y-auto pr-1"
      >
        <div
          v-for="item in tracingObjectDetail.frequency.statistic"
          :key="item.id"
          class="px-[17px] py-[10px] rounded-lg flex gap-[20px] items-center overflow-x-clip"
          :class="
            currentIO === item.profileUUID ? 'bg-ems-gray600' : 'bg-ems-gray700'
          "
          @click="selectIO(item)"
        >
          <img
            :src="
              !item.imagesAvatar
                ? require('@/static/img/no-img.png')
                : item.imagesAvatar
            "
            width="120"
            height="120"
            class="rounded-lg border-1 border-solid border-ems-gray300 mr-1 w-[120px] h-[120px]"
          />
          <div class="flex flex-col justify-center gap-[12px]">
            <span class="text-lg text-ems-gray100 font-semibold">{{
              item.name ? item.name : item.profileUUID
            }}</span>
            <div
              class="flex items-center flex-wrap gap-y-1"
              v-if="item.ioGroups && item.ioGroups.length > 0"
            >
              <span
                class="inline-block rounded-lg py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray200 bg-ems-tag1"
              >
                {{
                  listGroups[item.ioGroups[0]]
                    ? listGroups[item.ioGroups[0]].name.length > 20
                      ? `${listGroups[item.ioGroups[0]].name.substring(
                          0,
                          20
                        )}...`
                      : listGroups[item.ioGroups[0]].name
                    : ''
                }}
              </span>
              <a-popover
                placement="top"
                overlay-class-name="popup-groups"
                v-if="item.ioGroups.length > 1"
              >
                <template #content>
                  <div>
                    <span
                      class="inline-block rounded-lg py-[6px] text-ems-gray100 text-sm px-3 bg-ems-tag1 uppercase mr-1 mb-2 cursor-pointer"
                      v-for="(data, idx) in item.ioGroups"
                      :key="idx"
                    >
                      {{ listGroups[data].name }}
                    </span>
                  </div>
                </template>
                <span
                  class="inline-block rounded-lg p-1 px-2 bg-ems-gray500 text-ems-gray200"
                >
                  ...
                </span>
              </a-popover>
            </div>
            <span class="text-base text-ems-gray300"
              >{{ t('object_tracing.relationship') }}
              <span
                :class="
                  getRelationship(item)
                    ? 'font-bold text-ems-main2'
                    : 'font-normal text-ems-gray300'
                "
              >
                {{
                  getRelationship(item)
                    ? getRelationship(item)
                    : t('object_tracing.relationship-unknown')
                }}
              </span></span
            >
            <span class="text-base text-ems-gray300"
              >{{ t('object_tracing.frequency') }}
              <span class="text-ems-main2 font-bold">{{
                item.count
              }}</span></span
            >
            <span
              class="text-xs text-ems-gray300 px-[16px] py-[3px] block border-1px border-solid border-ems-gray300 rounded-full w-[fit-content] hover:bg-ems-gray700"
              @click="(e) => viewDetailIO(e, item)"
              >{{ t('object_tracing.view-info') }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, defineEmits, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { RELATIONSHIP } from '@/config/Constant.js';
import { FORM_MODE } from '@/util/common-constant';
import Empty from '@/components/empty/index.vue';
import dayjs from 'dayjs';

const props = defineProps({
  camInfo: {
    type: Object,
    default: () => {},
  },
  isLoading: {
    default: false,
  },
  groupCamera: {
    type: Array,
    default: () => [],
  },
});
const listCam = ref(Object.values(props.camInfo));
const emits = defineEmits(['selectIO', 'on-search-data']);
const { t } = useI18n();
const { state, dispatch } = useStore();
const route = useRoute();
const tracingObjectDetail = computed(
  () => state.tracingObject.tracingObjectDetail
);
const listGroups = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
const currentIO = ref(null);
const formState = reactive({
  groupCameraIds: [],
  cameraIds: [],
  dateFrom: dayjs().subtract(30, 'day'),
  dateTo: dayjs(),
});

const getRelationship = computed(() => (item) => {
  let arr = [];
  for (let i in item.relate) {
    if (item.relate[i]) {
      arr.push(t(RELATIONSHIP[i]));
    }
  }
  return arr.length > 0 ? arr.join(' ,') : '';
});
onMounted(async () => {
  if (route.query.profileUUID) {
    const selectedProfile = tracingObjectDetail.value.frequency.statistic.find(
      (item) => item.profileUUID === route.query.profileUUID
    );
    selectIO(selectedProfile);
  }
});
const router = useRouter();
const viewDetailIO = (e, item) => {
  e.preventDefault();
  e.stopPropagation();
  router.push({
    path: '/io-management/object-management/edit',
    query: {
      id: item.profileUUID,
      type: item.type,
      mode: FORM_MODE.VIEW,
    },
  });
};
const selectIO = (item) => {
  currentIO.value = item.profileUUID;
  emits('selectIO', item);
};
const onSearch = () => {
  emits('on-search-data', {
    id: route.query.id,
    params: {
      groupCameraIds: formState.groupCameraIds.join(','),
      cameraIds: formState.cameraIds.join(','),
      type: route.query.type,
      dateFrom: formState.dateFrom
        ? dayjs(formState.dateFrom).startOf('day').toDate()
        : null,
      dateTo: formState.dateTo
        ? dayjs(formState.dateTo).endOf('day').toDate()
        : null,
    },
  });
};
const setCameraId = (id) => {
  formState.cameraIds = [id];
};

const changeGroup = () => {
  onSearch();
  let cam = {};
  if (formState.groupCameraIds.length === 0) {
    cam = props.camInfo;
  } else {
    for (let group of props.groupCamera) {
      if (formState.groupCameraIds.includes(group.id)) {
        for (let id of group.cameraId) {
          if (props.camInfo[id]) {
            cam[id] = props.camInfo[id];
          }
        }
      }
    }
  }
  listCam.value = Object.values(cam);
};
defineExpose({ setCameraId });
</script>
<style lang="scss">
.common-io .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.common-io .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.common-io .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
.common-io .ant-select-selector {
  overflow-x: auto !important;
  padding-right: 0;
  padding-left: 4px;
}
.common-io .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
}
.common-io .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.common-io .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.common-io .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.common-io .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.25rem;
}
</style>
